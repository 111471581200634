import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

const BannerOpen = ({ data }) => {
    return (
        <BannerOpenStyled>
            <div className="About">
                <div className='equipa'>
                    {data.openday.map((member, key) => (
                        <article className='member' key={key}>
                            <Link to={member.link}>
                                <Img fluid={member.img.childImageSharp.fluid} className="image" alt={data.alt && data.alt} />
                            </Link>
                        </article>
                    ))}
                </div>
            </div>
        </BannerOpenStyled>
    )
}

export default BannerOpen

const BannerOpenStyled = styled.div`
.About {
    margin-top: calc(48px + 64 * (100vw - 769px) / 1151);
    padding: 5vw;
    max-width: 1920px;

    .equipa{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 4rem 4%;
        width: 25%;
        margin: 0 auto;

        @media only screen and (max-width: 1000px) {
            grid-template-columns: 1fr;
            margin-top: 5rem;
            gap: 1rem 4%;
            width: 90%;
        }
        
    }
    
}
`
