import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navbar"
import NavbarMobile from "../components/navbar/navbarMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"
import BannerOpen from "../components/openday/bannerOpen"

const EventosPage = ({ data }) => {

    return (
        <Layout home mobile={useBreakpoint().mobile}>
            <SEO titleTemplate="%s" title="Eventos | Carreira Dental Clinic" />
            {useBreakpoint().mobile ? (
                <>
                    <NavbarMobile />
                    <BannerOpen data={data.opendayJson.banner} />
                    <FooterMobile data={data.globalJson.footer} />
                </>
            ) : (
                <>
                    <Navbar />
                    <BannerOpen data={data.opendayJson.banner} />
                    <Footer data={data.globalJson.footer} />
                </>
            )}
        </Layout>
    )
}

export default EventosPage

export const Json = graphql`
query eventos {
  opendayJson {
    banner {
      openday {
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        link
      }
    }
  }
  globalJson {
    footer {
      newsletter {
        subscreva
        btnTxt
      }
      aveiro {
        title
        morada
        contacto
      }
      agueda {
        title
        morada
        contacto
      }
      porto {
        title
        morada
        contacto
      }
      viseu {
        title
        morada
        contacto
      }
      oliveira {
        title
        morada
        contacto
      }
      email
      schedule
      bg {
        publicURL
      }
    }
  }
}
`
